import React, { useContext } from 'react';
import { ThemeContext } from './context/ThemeContext.jsx';
import darkIcon from '../assets/ChangeToDarkIcon.png';
import lightIcon from '../assets/ChangeToLightIcon.png';

const Header = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleClick = (e) => {
    document.getElementById(e.target.textContent || e.target.innerText)?.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <header className={`"py-6 px-4 h-14 sticky top-0 z-10 overflow-y-hidden bg-primary-lightest dark:bg-primary-darkest flex items-center "`}>
      <div className="container mx-auto flex justify-center sm:justify-between items-center">
        <div className="w-0 sm:w-auto">
          <h1 className="text-2xl font-bold invisible sm:visible">Keagan Holmes</h1>
        </div>
        <nav className="flex items-center">
          <ul className="flex space-x-4 mr-4 text-sm sm:text-lg">
            <button onClick={handleClick}>About</button> 
            <button onClick={handleClick}>Projects</button> 
            <button onClick={handleClick}>Resume</button> 
            {/* <button onClick={handleClick}>Photos</button>  */}
            <button onClick={handleClick}>Contact</button> 
          </ul>
          <button onClick={toggleDarkMode} className="w-7 h-6">
            <img src={isDarkMode ? lightIcon : darkIcon} alt="Toggle dark mode" />
          </button>
        </nav>
      </div>
    </header>
  );
}

export default Header;
