import React from 'react';
import defaultProjectImage from '../../assets/defaultProjectImage.png'




const Project = ({title, date, content, img, imgAltText, linkToProjectTitle, linkToProject, isOnLeftSide=true}) => {
  // This is a stupid way to do this but I don't feel like rewriting the whole thing. 
  var imageCircleMargins = (isOnLeftSide ? "sm:left-4 sm:ml-8 sm:mr-28" : "sm:right-4 sm:ml-28 sm:mr-8")
  var imageCircle = (<img src={img ? img : defaultProjectImage} alt={imgAltText ? imgAltText : "A poorly drawn image of a puzzle piece."} className={"m-auto scale-95 hover:scale-100 ease-in duration-75 object-contain bg-white " + (linkToProject ? "" : imageCircleMargins)+ " w-32 h-32 rounded-full"}/>)
  
  return (
    <div className={"container mx-auto flex flex-col text-center " + (isOnLeftSide ? "sm:left-0 sm:text-left sm:flex-row" : "sm:right-0 sm:text-right sm:flex-row-reverse")}>
      {linkToProject ? 
        <a href={linkToProject} target="_blank" rel="noreferrer" className={"flex flex-grow-0 flex-shrink-0 " + (linkToProject ? imageCircleMargins : "")}>{imageCircle}</a> 
        : imageCircle}

      <div>
        <h3 className={"text-lg font-bold " + (date ? "" : "mb-4")}>{title}</h3>
        {date ? <h4 className="text-sm italic mb-4">{date}</h4> : ""}
        {content}
        {linkToProject ? <a href={linkToProject} target="_blank" rel="noreferrer"><i>{linkToProjectTitle ? linkToProjectTitle : "View this project online"}</i></a> : ""}
        <br/>
      </div>
      <div className={"w-0 xl:w-[272px] flex-grow-0 flex-shrink-0"} /> {/* spacer */}
    </div>
  );
}

export default Project;