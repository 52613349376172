import React from 'react';
// import {useState} from 'react'
import ScrollTop from './ScrollTop';
import Project from './templates/ProjectTemplate';

import Student_Teacher_Reinforcement_Learning from '../assets/Student_Teacher_Reinforcement_Learning.pdf';
import RIS_Project_Report from '../assets/RIS_Project_Report.pdf';
import Holmes_Applications_of_Visual_Gesture_Recognition from '../assets/Holmes_Applications_of_Visual_Gesture_Recognition.pdf';

import unitree_walking from '../assets/unitree_walking.jpg';
import QA_Dialogue_Assistant_Poster from '../assets/QA_Dialogue_Assistant_Poster.jpg';
import MAI_Project_Report_ICELS from '../assets/MAI_Project_Report_ICELS.jpg';
import CarbonTrainerLight from '../assets/CarbonTrainerLight.jpeg';
import duckietown from '../assets/duckietown.jpg';
import carbdown from '../assets/carbdown.jpg';


const Projects = () => {
  // const [browserZoomLevel, setBrowserZoomLevel] = useState(100)
  
  // window.addEventListener('resize', () => {
  //   setBrowserZoomLevel(window.devicePixelRatio * 100)
  // })
  
  return (
    <section id="Projects" className="py-6 px-4 mx-auto self-center w-[80vw]">
      <ScrollTop />
      
      <div className="container mx-auto flex flex-col sm:flex-row">
        <div className="w-32 m-auto sm:left-4 sm:ml-8 sm:mr-28" />
        <h2 className="text-2xl font-bold mb-2 text-center sm:text-left">{"Projects"}</h2>
      </div>

      <div className="flex flex-col space-y-10">
        {/* <Project
            title=""
            content={<p></p>}
            linkToProject={""}
            /> */}

        <Project 
          title="Bachelor's Thesis" 
          date="February 2023 - June 2023"
          content={<p>My bachelor's thesis was titled Visual Gesture Recognition and Applications in Smartwatches and involved thoroughly researching the field of gesture recognition and developing software. 
            I wrote code in Python which allowed for seamless data collection and processing, following by neural network training and data analysis. 
            The project was overall quite a success, though I would have of course liked to add some more content if not for the significant time constraint.</p>}
          linkToProjectTitle={"Read the paper"}
          linkToProject={Holmes_Applications_of_Visual_Gesture_Recognition}
          />

        <Project
          title="Student-Teacher Learning for Efficient Torque Control of Quadrupeds"
          date="October 2023 - present"
          img={unitree_walking}
          content={<p>Developed a novel framework for locomotion that trains an agent to use position control with reinforcement learning, whose actions are used train another which uses torque control through imitation learning.</p>}
          linkToProjectTitle={"Read the report"}
          linkToProject={Student_Teacher_Reinforcement_Learning}
          isOnLeftSide={false}
          />

        <Project
          title="Image Captioning Evaluation in Latent Spaces"
          date="March 2023 - August 2023"
          img={MAI_Project_Report_ICELS}
          content={<p>In this project for a Multimodal Artifical Intelligence class, my group showed that many novel image captioning evaluation metrics, including those done by large language models, are outperformed by several comparative distance metrics in the latent space of the embedded space of the Flickr8k dataset.
            We showed these distances correlate more strongly with human judgement than BLEU, BERT-S, SPICE, and <a href={"https://arxiv.org/pdf/2310.12971"} target="_blank" rel="noreferrer"><i>LLM-based metrics</i></a>.
          </p>}
          linkToProjectTitle={"View the repository"}
          linkToProject={"https://github.com/OliverGrein/Image-Captioning-6Evaluation"}
          />

        <Project
          title="CARE: Collaborative AI-Assisted Reading Environment"
          date="October 2023 - February 2024"
          img={QA_Dialogue_Assistant_Poster}
          content={<p>Extended an existing project to integrate three question answering (QA) models to find information in a loaded PDF file: extractive- (with RoBERTa), retrieval-augmented, and retrieval-augmented generative QA</p>}
          linkToProjectTitle={"Read the original publication"}
          linkToProject={"https://arxiv.org/pdf/2302.12611"}
          isOnLeftSide={false}
          />

        <Project
          title="Carbon Trainer"
          date="November 2022"
          img={CarbonTrainerLight}
          content={<p>This project for the 2022 JacobsHack! hackathon estimated the carbon emission savings of connecting any two cities over 100,000 inhabitants by a railway line, compared to a baseline of automobile traffic</p>}
          linkToProjectTitle={"View the repository"}
          linkToProject={"https://github.com/keaganchs/hackathon"}
          />

        <Project
          title="Project Carbdown"
          date="August 2022 - February 2023"
          img={carbdown}
          content={<p>I was involved in a community impact project (CIP) at Constructor University called <i>Project Carbdown</i> in my fifth and sixth semesters,
            aimed to combat global warming through a process known as <a href="https://en.wikipedia.org/wiki/Enhanced_weathering" target="_blank" rel="noreferrer">enhanced weathering</a>.
            I was personally involved in sensor development, which would allow for verification of the drawdown of CO2 from the atmosphere and allowcation of carbon credits; as well as web development for raising awareness of the project.
            We recieved an award from the university for our work on the project for it being the most impactful of the year. Work was done in part in affiliation with the <a href="https://www.carbon-drawdown.de/home-en" target="_blank" rel="noreferrer">Carbon Drawdown Initiative GmbH</a></p>}
          isOnLeftSide={false}
          />

        <Project
          title="Autonomous Driving with MIT's Duckietown"
          date="Feb 2022 - May 2022"
          img={duckietown}
          content={<p>Implemented several systems for autonomous driving on a miniature environment, implementing traffic sign, vehicle, and lane detection. Conducted a survey on sensors used for autonomous driving.</p>}
          linkToProjectTitle={"Read the report"}
          linkToProject={RIS_Project_Report}
          />
        
      </div>
    </section>
  );
}

export default Projects;
