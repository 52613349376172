import React from 'react';
import { ThemeProvider } from './components/context/ThemeContext';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Resume from './components/Resume';
// import Photos from './components/Photos';
import Contact from './components/Contact';

const App = () => {
  return (
    <ThemeProvider>
      <div className={"min-h-screen selection:bg-gray-300 dark:selection:bg-gray-700"}>
        <Header />
        <div className="flex flex-col mx-10 justify-between">
          <About />
          <Projects />
          <Resume />
          {/* <Photos /> */}
          <Contact />

          
          <div className="min-h-[100vh]" />
            
          <div className="mt-[10vh] mb-[10vh] text-center">
            <p>© Keagan Holmes 2024. All rights reserved.</p>
            <p>There are links to external pages on this site. No responsibility is taken for the content displayed on these external pages.</p>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
